import store from "@/store";
import superAdmin from "./super-admin";
import employee from "./employee";
import user from "./user";
import partner from "./partner";

const routesArray = [];
const userRole = store.getters["auth/getRole"];
/*
  Define role for super admin
*/

if (userRole === "Super-admin") {
  routesArray.push(...superAdmin);
}
/*
  Define role for employee
*/

if (userRole === "Employee") {
  routesArray.push(...employee);
}

/*
  Define role for the user
*/
if (userRole === "User") {
  routesArray.push(...user);
}

/*
  Define role for the partner
*/
if (userRole === "Partner") {
  routesArray.push(...partner);
}

export default routesArray;
