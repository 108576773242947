<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="unreadNotificationsCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex align-items-center">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("notifications.Notifications") }}
        </h4>
        <!-- <b-badge pill variant="light-primary"> 6 New </b-badge> -->
        <b-button
          v-if="unreadNotificationsCount"
          :disabled="isMarking"
          variant="link"
          size="sm"
          @click="markAllasRead"
          >{{
            isMarking
              ? $t("notifications.Loading")
              : $t("notifications.markAllasRead")
          }}</b-button
        >
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link
        v-for="(notification, index) in notifications"
        :key="`noti_${index}`"
      >
        <b-media :class="{ 'bg-light-info': !notification.read }">
          <template #aside>
            <b-avatar size="32" icon="" />
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data.title }}
            </span>
          </p>
          <div class="notification-text">
            {{
              $i18n.locale == "en"
                ? notification.data.messageEnglish.title
                : notification.data.messageArabic.title
            }}
          </div>
          <div class="notification-text">
            {{
              moment(notification.createdAt).locale($i18n.locale).fromNow(false)
            }}
          </div>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer text-center">
      <b-link
        to="/notifications"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="link"
        block
        >{{ $t("notifications.Read_all_notifications") }}</b-link
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import store from "@/store";
import axios from "axios";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isMarking: false,
    };
  },
  computed: {
    ...mapGetters({
      notifications: "auth/getNotifications",
      unreadNotificationsCount: "auth/getUnreadNotificationsCount",
    }),
  },
  methods: {
    ...mapActions({
      updateUnreadCount: "auth/updateUnreadNotificationsCount",
    }),
    moment: function (input) {
      return moment(input);
    },
    markAllasRead: async function () {
      try {
        this.isMarking = true;
        const res = await axios.put("notifications/updateStatus");
        this.updateUnreadCount(0);
        this.isMarking = false;
      } catch (e) {
        this.isMarking = false;
      }
    },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style scoped>
[dir="rtl"]
  .header-navbar
  .navbar-container
  ul.navbar-nav
  li.dropdown-notification
  .notification-text {
  font-family: "Tajawal";
}
[dir="rtl"]
  .header-navbar
  .navbar-container
  ul.navbar-nav
  li.dropdown-notification
  .dropdown-menu-header
  .dropdown-header {
  font-family: "Tajawal";
}

[dir="rtl"]
  .header-navbar
  .navbar-container
  ul.navbar-nav
  li.dropdown-notification
  .dropdown-menu-footer {
  font-family: "Tajawal";
}
</style>
