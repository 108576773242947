<template>
  <div id="dropdown-grouped" variant="link" class="dropdown-language" right>
    <div class="ml-1 mr-1 cursor-pointer" @click="changeLanguage()">
      <b-img :src="currentLocale.img" height="14px" width="22px" :alt="currentLocale.locale" />
      <!-- <span class="ml-50 text-body">{{ currentLocale.name }}</span> -->
    </div>
  </div>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from "bootstrap-vue";
import store from "@/store";
import { setLogLevel } from "@firebase/app";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      return this.locales.find((l) => l.locale !== this.$i18n.locale);
    },
  },
  // computed: {
  //   ...mapGetters({
  //     user: "auth/user",
  //   }),
  // },
  methods: {
    ...mapActions({
      changeLang: "locale/changeLang",
      checkRTL: "app-config/changeLang",
    }),

    changeLanguage() {
      if (localStorage.getItem("language") == "ar") {
        this.setLocale("en");
      } else {
        this.setLocale("ar");
      }
    },

    setLocale(value) {
      // alert(this.$i18n.locale);
      this.$i18n.locale = value;
      // store.commit("appConfig/TOGGLE_RTL", true);
      if (value === "ar") {
        store.commit("appConfig/TOGGLE_RTL", true);
        // store.dispatch("local/setLanguage", "ar");
        // store.commit("locale/changeLang");
        this.changeLang();
      }
      if (value === "en") {
        store.commit("appConfig/TOGGLE_RTL", false);
        this.changeLang();
      }
      window.location.reload();
    },
    checkDir() {
      // console.log(localStorage.getItem("language"));

      if (localStorage.getItem("language") == "ar") {
        document.documentElement.setAttribute("dir", "rtl");
        document.body.style.fontFamily = "Tajawal";
      } else {
        document.documentElement.setAttribute("dir", "ltr");
        // document.body.style.fontFamily = "Montserrat,Helvetica,Arial, serif";
        document.body.style.fontFamily = "nftbplus,sans-serif";
      }
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        img: require("@/assets/images/flags/en.png"),
        name: "English",
      },
      {
        locale: "ar",
        img: require("@/assets/images/flags/ar.png"),
        name: "العربية",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
    };
  },
  mounted() {
    this.checkDir();
  },
};
</script>

<style scoped>
.text-body {
  color: #366ce3 !important;
}
</style>
